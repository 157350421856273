.headerWrapper {
  display: flex;
  width: 100%;
  border-bottom: solid var(--border-color-primary) 1px;
  padding-right: 8px;
  padding-bottom: 8px;
}

.filtersControlWrapper {
  position: absolute;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius);
  top: 32px;
  right: 20px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.headerCellWrapper {
  padding: 8px;
  padding-top: 12px;
}

.invisibleSortBtn {
  opacity: 0;
  &:hover {
    opacity: 0.6;
  }
}

.totalFilterState {
  width: 20px;
  height: 20px;
  border-radius: 50%;

  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.clearAllBtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  border: solid var(--border-color-primary) 1px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.filterStateControl {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid var(--border-color-primary) 1px;;
  svg {
    width: 16px;
    height: 16px;
  }
}

.columnState {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 4px;

  button {
    padding: 2px !important;
  }
}

.nameAndSortDirection {
  display: flex;
  align-items: start;

  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    svg {
      opacity: 0.6;
    }
  }
}

.nameWrapperShortSpace {
  width: calc(100% - 20px);
}

.hovered {
  cursor: pointer !important;
}

.sortBtn {
  margin-right: 4px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.removeBtn {
  position: absolute !important;
  top: -12px;
  right: 0;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.filterState {
  margin-left: 4px;
  margin-top: 2px;
  width: 16px !important;
  height: 16px !important;
}

.notApplied {
  fill: var(--typo-secondary) !important;
}

.applied {
  fill: var(--red) !important;
}

.columnCellWrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}