.mapOuterWrapper {
  padding: 8px 32px;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 400px;
}

.distanceInfo {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.mapWrapper {
  position: relative;
  width: 327px;
  height: 491px;
  overflow: hidden;

  div {
    transform: none !important;
    left: 0 !important;
    top: 0 !important;
  }
}
