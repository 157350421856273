.textM {
  font-family: Geometria, sans-serif;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--typo-primary);

  @media (max-width: 900px) {
    font-size: 12px;
  }
}