.tabWrapper {
  display: flex;
  align-items: end;
  min-height: var(--tab-controls-height);
  max-height: var(--tab-controls-height);
  width: 100%;
}

.tabItem {
  @media (max-width: 1680px) {
    padding: 8px 4px;
  }
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  min-width: fit-content;
  cursor: pointer;

  div {
    color: var(--typo-secondary);
  }

  &:hover {
    div {
      transition-duration: 0.5s;
      color: var(--typo-primary);
    }
  }

  &:active {
  }

  &:focus {
    outline: none;

    div {
      transition-duration: 0.5s;
      color: var(--typo-primary);
    }
  }
}

.selected {
  border-bottom: solid var(--yellow) 2px !important;

  div {
    color: var(--typo-primary);
  }
}
