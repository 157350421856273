.userEmail {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 8px;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius);
  width: fit-content;
}

.resultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;

  div {
    font-size: 20px !important;
  }
 }