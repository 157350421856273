.textThCell {
  margin: 0;
  font-family: Geometria, sans-serif;
  font-size: 13px;
  font-style: normal;
  color: var(--typo-primary);
  font-weight: 600;
  line-height: 17px;
  opacity: 1;
  z-index: 14;
  background-color: var(--bg-primary);

  @media (max-width: 900px) {
    font-size: 11px;
  }
}
