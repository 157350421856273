.textXs {
  margin: 0;
  font-family: Geometria, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  color: var(--typo-primary);

  @media (max-width: 900px) {
    font-size: 8px;
  }
}
