.textInput {
  //height: 40px;

  fieldset {
    border-color: var(--border-color-primary);
  }

  &:hover fieldset {
    border: 1px solid var(--border-color-primary) !important;
  }

  input {
    font-family: Geometria, sans-serif !important;
    font-size: 16px !important;
    color: var(--typo-primary) !important;
    padding-right: 4px;

    border-radius: var(--border-radius);
  }

  .clrBtn {
    margin-right: -4px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }

  .clrBtnIcn {
    width: 16px;
    height: 16px;
  }
}

.success {
  fieldset {
    border-color: var(--green);
  }
}

.error {
  fieldset {
    border-color: var(--red);
  }
}

.warning {
  fieldset {
    border-color: var(--yellow);
  }
}