.titleWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.spinnerWrapper {
  position: relative;
  width: 60px;
  height: 60px;
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 16px;
}