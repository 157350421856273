.authPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &:focus {
  outline: none;
  }
}

.authFormRow {
  .inputWrapper {
    background-color: dodgerblue !important;
    width: 50% !important;
  }
}

.companyLogoOnTop {
  position: absolute;
  top: 64px;
}

.companyLogo {
  position: absolute;
  top: 200px;
}

.formWrapper {
  box-shadow: inset -2px 0px 1px 0px rgba(34, 60, 80, 0.18);
  padding: 24px 36px;
  width: 450px;
  min-width: 450px;
  height: fit-content;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius);
}

.formTitle {
  width: 100%;
  margin-bottom: 48px;
  padding-bottom: 20px;
  border-bottom: solid var(--border-color-primary) 1px;
}

.showPswBtn {
  svg {
    width: 16px;
    height: 16px;
  }

  margin-right: -4px;
}

.additionalActionRow {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-right: -8px;
  margin-left: -8px;

  div {
    color: var(--typo-primary);
    font-size: 12px;
    padding: 8px;
    border: solid var(--border-color-secondary) 1px;
    cursor: pointer;

    &:hover {
      outline: solid var(--border-color-primary) 1px;
      border-radius: var(--border-radius);
    }
  }
}

.actionRow {
  display: flex;
  justify-content: end;
  margin-top: 32px;
}