.selectWrapper {
  width: 100%;
  height: 38px;

  > div {
    padding: 4px;
  }

  font-family: Geometria, sans-serif !important;
  font-weight: 300 !important;
  font-size: 16px !important;

  fieldset {
    border: 1px solid var(--border-color-primary) !important;
  }

  &:hover fieldset {
    border: 1px solid var(--border-color-primary) !important;
  }

  &:focus fieldset  {
    background-color:  var(--border-color-primary);
    border: solid  var(--border-color-primary) 1px !important;
  }
}

.disabled {
  &:hover fieldset {
    border: 1px solid var(--border-color-primary) !important;
  }
}

.listItem {
  margin: 4px !important;
  padding: 4px !important;
  border-bottom: solid var(--border-color-primary) 1px !important;
  border-top: solid var(--border-color-primary) 1px !important;
  background-color: var(--bg-primary) !important;

  &:hover {
   div {
     color: var(--typo-primary) !important;
   }
  }
}

.selectedItemsWrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.selectedItem {
  max-height: 20px;
  max-width: 50px;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  margin: 2px;
  background-color: var(--bg-secondary);
  padding: 4px 8px;
  border-radius: var(--border-radius);
}