.regFormWrapper {
  position: relative;
  padding: 8px;
  width: 30vw;
  min-width: 450px;
  min-height: fit-content;
  max-height: 80vh;
  height: 640px;
}

.formTitle {
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid var(--border-color-primary) 1px;
}

.formContent {
  position: relative;
  padding: 16px;
  height: 90%;
  overflow: auto;
}

.showPswBtn {
  svg {
    width: 16px;
    height: 16px;
  }

  margin-right: -4px;
}