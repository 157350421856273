.checkboxWrapper {
  width: 38px;
  height: 38px;

  svg {
    transform: scale(0.8);
  }
}

.disabled {
  color: var(--disabled) !important;
}

.active {
  color: var(--red) !important;
}