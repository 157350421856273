.primaryButton {
  text-transform: none !important;
  background-color: var(--red) !important;
  height: 36px;
  box-shadow: none !important;
  border-radius: var(--border-radius);

  div {
    color: var(--typo-white) !important;
  }
  &:hover {
    opacity: 0.8;
  }

  &:active {
    div {
      color: var(--typo-white) !important;
    }
  }

  &:focus {
    outline: solid var(--red) 2px;
  }

  &:disabled {
    div {
      color: var(--typo-primary);
    }
    opacity: 0.5;
  }
}


