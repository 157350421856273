.secondaryButton {
  background-color: var(--yellow);
  height: 36px;
  border-color: var(--yellow) !important;
  border-radius: var(--border-radius);
  text-transform: none !important;

  div {
    color: var(--yellow);
  }

  &:hover {
    background-color: var(--yellow) !important;
    div {
      color: var(--typo-white);
    }
  }

  &:active {
    div {
      color: var(--typo-primary);
    }
  }

  &:focus {
    border: solid var(--yellow) 2px;
  }

  &:disabled {
    opacity: 0.4;
  }
}

