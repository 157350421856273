
.contentWrapper {
}

.performanceWrapper {
  padding: 16px;
  height: 75vh;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.validationNoticeMessage {
  position: absolute;
  bottom: 80px;
  right: 108px;

  color: var(--blue) !important;
}

.validationErrorMessage {
  position: absolute;
  bottom: 108px;
  right: 160px;

  color: var(--red) !important;
}

.infoWrapper {
  position: relative;
  width: 650px;
}

.tabs {
  min-height: fit-content !important;
}

