.h2Text {
  margin: 0;
  font-family: TUZ, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 39px;
  color: var(--red);

  @media (max-width: 900px) {
    font-size: 16px;
  }
}
