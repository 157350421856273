.selectorWrapper {
  position: absolute;
  left: 0;
  top: 8px;
  display: flex;
  gap: 8px;
  margin-top: 4px;
}

.selectedGroup {

  background-color: var(--blue);

  div {
    color: var(--typo-white) !important;
  }
}

.groupItem {
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  border: solid var(--border-color-primary) 1px;
  padding: 8px;
  border-radius: var(--border-radius);
}