.selectWrapper {
  width: 100%;
  height: 38px;
  min-width: 80px;

  font-family: Geometria, sans-serif !important;
  font-weight: 300 !important;
  font-size: 16px !important;

  &:hover fieldset {
    border: 1px solid var(--border-color-primary) !important;
  }
}

.menuItem {
  margin: 4px !important;
  font-family: Geometria, sans-serif !important;
  border-bottom: solid var(--border-color-secondary) 1px !important;
  border-top: solid var(--border-color-secondary) 1px !important;
  background-color: var(--bg-primary) !important;
  min-height: 36px !important;

  &:hover {
    background-color: var(--bg-secondary) !important;
  }
}
