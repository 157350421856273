.mainPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.redirectionAction {
  margin-top: 32px;

  div {
    color: var(--border-color-primary);
    padding: 8px;
    cursor: pointer;
    border: solid var(--border-color-primary) 1px;

    &:hover {
      outline: solid var(--border-color-primary) 1px;
    }
  }
}