.infoWrapper {
  width: calc(100vw - var(--viewport-padding) * 2 - var(--content-wrapper-padding) * 2 - 2px);
  height: 100%;
  padding: var(--viewport-padding);
}

.contentWrapper {
  position: relative;
  background-color: var(--bg-secondary);
  height: calc(100% - var(--content-title-height) - var(--table-bottom-content-height));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding-top: 220px;
  padding-bottom: 32px;
}

.mobilePreview {
  padding: 32px 16px;
  background-color: var(--bg-primary);
  width: 375px;
  min-height: 900px;
  height: fit-content;
  border-radius: var(--border-radius-mobile);
}

.bottomSpace {
  min-height: var(--table-bottom-content-height);
  max-height: var(--table-bottom-content-height);
  display: flex;
  justify-content: end;
  align-items: end;
}

.addBtnWrapper {
  position: absolute;
  left: 0;
}

.successUploaded {
  color: var(--green) !important;
  margin-left: 16px;
}

.imagePreview {
  margin-left: auto;
  margin-bottom: 32px;
  margin-top: 32px;
  min-height: 160px;
  min-width: 335px;
  max-height: 160px;
  max-width: 335px;
  height: 160px;
  width: 335px;

  overflow: hidden;

  img {
    width: 335px;
  }

  background-color: var(--yellow);
}

.uploadFileInput {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formTitle {
  margin-bottom: 32px;
}

.infoForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 500px;
  width: 800px;
}

.commonParamRow {
  margin-top: 12px !important;

  div {
    font-size: 14px;
  }
}

.title {
  font-family: TUZ, sans-serif;
  color: var(--red);
  font-size: 64px;
  line-height: 64px !important;
  font-weight: 900;
  border-bottom: solid var(--border-color-secondary) 1px;
  padding-bottom: 32px;
}

.subTitle {
  padding-top: 32px;
  font-family: TUZ, sans-serif;
  font-size: 44px;
  font-weight: 900;
  line-height: 44px !important;
}

.imageWrapper {
  margin-top: 22px;
  margin-bottom: 16px;
  margin-left: 8px;
  height: 160px;
  width: 335px;
  position: relative;
}

.image {
  position: absolute;
  height: 160px;
  width: 335px;
  z-index: 2;
}

.imageBg {
  height: 160px;
  width: 335px;
  z-index: 1;
  position: absolute;
  top: -8px;
  left: -8px;
  background-color: var(--yellow);
}

.infoText {
  font-size: 15px;
  line-height: 20px !important;
  padding-bottom: 32px;
  border-bottom: solid var(--border-color-secondary) 1px;
}