.textInput {
  //height: 40px;

  fieldset {
    border-color: var(--border-color-primary) !important;
  }


  &:hover fieldset {
    border: 2px solid var(--yellow) !important;
  }

  input {
    font-family: Geometria, sans-serif !important;
    font-size: 16px !important;
    color: var(--typo-primary) !important;
    font-weight: 300 !important;
    border-radius: var(--border-radius);

    &:focus {
      outline: 1px solid var(--yellow) !important;
    }
  }

  .clrBtn {
    margin-right: -4px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
  }

  .clrBtnIcn {
    width: 16px;
    height: 16px;
  }
}

.success {
  fieldset {
    border-color: var(--border-color-primary);
  }
}

.error {
  fieldset {
    border-color: var(--border-color-primary);
  }
}

.warning {
  fieldset {
    border-color: var(--border-color-primary);
  }
}