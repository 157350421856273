.datePickerWrapper {
  div {
    height: 38px !important;
    font-family: Geometria, sans-serif !important;
    input {
      font-size: 12px !important;
      font-weight: 300 !important;
      color: var(--typo-primary) !important;
    }
  }

  fieldset {
    border-color: var(--border-color-primary) !important;

  }

  &:hover fieldset {
    border: 1px solid var(--border-color-secondary) !important;
  }

  button {
      transform: scale(0.7);
      padding: 1px !important;
      margin: 1px !important;
  }
}