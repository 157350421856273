.showsWrapper {
  width: calc(100vw - var(--viewport-padding) * 2 - var(--content-wrapper-padding) * 2 - 2px);
  height: 100%;
  padding: var(--viewport-padding);
}

.contentWrapper {
  height: calc(100% - var(--content-title-height) - var(--table-bottom-content-height));
}

.modalWrapper {
  @media (max-width: 1680px) {
    & > div > div {
      width: 90vw;
      display: flex;
      justify-content: center;
    }
  }
}

.confirmDialog {
  margin: 24px 16px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logoInTable {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: var(--border-radius);
}

.addBtnWrapper {
  position: absolute;
  left: 0;
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.columnDateFilterWrapper {
  display: flex;

  div {
    height: 31px !important;
    padding-right: 4px !important;
    font-size: 12px !important;

    input {
      padding-left: 8px !important;
    }

    button {
      transform: scale(0.6);
      padding: 4px !important;
      margin: 4px !important;
    }
  }

  > div div div {
    margin-right: 4px !important;
  }

  div div div div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.columnFilterWrapper {
  position: relative;
  input {
    height: 14px !important;
  }
}

.columnSelectFilterWrapper {
  position: relative;
  > div {
    height: 30px !important;
  }
}

.bgColorExample {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 8px);
  height: 40px;
  border-radius: var(--border-radius);
}

.bottomSpace {
  min-height: var(--table-bottom-content-height);
  max-height: var(--table-bottom-content-height);
  display: flex;
  justify-content: end;
  align-items: end;
}


.btn {
  transform: scale(80%);
  color: var(--red) !important;
}