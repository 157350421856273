.formRowWrapper {
  width: 100%;
  margin-top: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verticalRow {
  width: 100%;
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 16px 0;
}

.topDivider {
  border-top: solid var(--bg-secondary) 1px;
  padding-top: 16px;
}

.bottomDivider {
  border-bottom: solid var(--bg-secondary) 1px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.topLabelWrapper {
  display: flex;
  width: 40%;
  margin-bottom: 16px;
  margin-top: 8px;
}

.labelWrapper {
  display: flex;
  width: 40%;
}

.validationNotice {
  text-align: justify;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  bottom: -12px;
  left: 0;
  color: var(--red);
}

.validationNoticeUnderLine {
  top: -4px;
  position: absolute;
  color: var(--red)
}

.inputWrapper {
  width: 60%;
}

.fullWidthInputWrapper {
  width: 100%;
  overflow: auto;
}

.requiredSign {
  transform: translateY(-20%);
  margin-left: 8px;
  color: var(--red)
}