.virtualTableWrapper {
  width: 100%;
  position: relative;
  overflow-y: auto;
  min-width: fit-content;
  height: calc(100% - var(--max-table-header-height) - 20px);
}

.tableWrapper {
  height: 100%;
  overflow: auto;
}

.scrollableBody {

}

@keyframes lastUpdEffect {
  from {
    background-color: var(--bg-secondary);
  }

  to {
    background-color: var(--bg-primary);
  }
}

.lastUpdated {
  animation: lastUpdEffect;
  animation-duration: 3s;
}

.tableRow {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: solid var(--border-color-secondary) 1px;

}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: var(--border-color-primary);
  }
}

.tableCell {
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 8px !important;
}