.modalWindow {
  & > div > div {
    max-width: 95vw !important;
    min-width: 200px;
    min-height: 75px;
  }
}

.dialogTitle {
  display: flex;
  justify-content: center;
  padding-right: 48px !important;

  span {
    min-height: 1rem;
  }
}
