body {
  margin: 0;
  font-family: 'Geometria', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: TUZ;
  src: url("fonts/TUZ_tomsk_dit.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Geometria;
  src: url("fonts/Geometria/geometria_medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Geometria;
  src: url("fonts/Geometria/geometria_light.otf");
  font-weight: 300;
}

@font-face {
  font-family: Geometria;
  src: url("fonts/Geometria/geometria_bold.otf");
  font-weight: 700;
}



.Mui-focused {
 fieldset {
    border: solid var(--grayPantoneCool3C) 1px !important;
    box-shadow: -3px 0px 5px 0px rgba(34, 60, 80, 0.18);
  }
}

.MuiAlert-standard {
  display: flex;
  align-items: center;
  font-family: tuz, sans-serif !important;
  color: var(--border-color-primary);
  font-size: 16px !important;

  svg {
    padding-bottom: 4px;
    width: 18px;
    height: 18px;
  }
}

.MuiSnackbar-root {
  box-shadow: 5px 5px 5px 1px rgba(34, 60, 80, 0.24);
}

:root {
  --typo-primary: rgba(31, 31, 45, 1);
  --typo-secondary: rgba(181, 181, 181, 1);
  --typo-white: rgba(255, 255, 255, 1);

  --bg-primary: rgb(255, 255, 255);
  --bg-secondary: rgba(238, 238, 238, 1);

  --border-color-primary: rgba(60, 60, 67, 0.36);
  --border-color-secondary: rgba(238, 238, 238, 1);

  --disabled: rgba(201, 201, 201, 1);

  --red: rgba(217, 34, 24, 1);
  --blue: rgba(0, 136, 204, 1);
  --yellow: rgba(249, 180, 33, 1);
  --green: rgba(41, 167, 26, 1);

  --tab-controls-height: 60px;
  --content-wrapper-padding: 4px;
  --content-title-height: 68px;
  --table-bottom-content-height: 60px;
  --max-table-header-height: 80px;

  --viewport-padding: 16px;
  --border-radius: 4px;
  --border-radius-mobile: 40px;
  --header-height: 40px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--blue);
}

::-webkit-scrollbar-thumb {
  background-color: var(--disabled);
  border-radius: 8px;
}
