.regFormWrapper {
  padding: 8px;
  width: 25vw;
  min-width: 300px;
}

.formTitle {
  width: 100%;
  margin-bottom: 48px;
  padding-bottom: 20px;
  border-bottom: solid var(--grayPantoneCool3C) 1px;
}

.formContent {
  position: relative;
  padding: 4px;
}

.showPswBtn {
  svg {
    width: 16px;
    height: 16px;
  }

  margin-right: -4px;
}