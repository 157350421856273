.recoveryPasswordFormWrapper {
  padding: 8px;
  width: 25vw;
  min-width: 300px;
  position: relative;
}

.formTitle {
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid var(--border-color-primary) 1px;
}

.formContent {
  position: relative;
  padding: 4px;
  margin-bottom: 32px;
}

.showPswBtn {
  svg {
    width: 16px;
    height: 16px;
  }

  margin-right: -4px;
}