.usersWrapper {
  width: calc(100vw - var(--viewport-padding) * 2 - var(--content-wrapper-padding) * 2 - 2px);
  height: 100%;
  padding: var(--viewport-padding);
}

.confirmDialog {
  margin: 48px 16px 32px 16px;
}

.contentWrapper {
  height: calc(100% - var(--content-title-height) - var(--table-bottom-content-height));
}

.bottomSpace {
  min-height: var(--table-bottom-content-height);
  max-height: var(--table-bottom-content-height);
  display: flex;
  justify-content: end;
  align-items: end;
}

.columnDateFilterWrapper {
  display: flex;

  div {
    height: 31px !important;
    padding-right: 4px !important;
    font-size: 12px !important;

    input {
      padding-left: 8px !important;
      margin-right: 16px !important;
    }

    button {
      transform: scale(0.6);
      padding: 4px !important;
      margin: 4px !important;
    }
  }

  div div div div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.columnFilterWrapper {
  position: relative;
  input {
    height: 14px !important;
  }
}

.checkBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.columnSelectFilterWrapper {
  position: relative;
  > div {
    height: 30px !important;
  }
}

.btn {
  transform: scale(80%);
  color: var(--red) !important;
}