
.checkPointsWrapper {
  min-width: 470px;
  overflow: auto;
  height: calc(70vh - 40px);
  padding: 8px 16px;
}

.addBtnWrapper {
  position: absolute;
  right: -34px;
  top: 4px;
}

.addBtn {
  color: var(--red);
}

.sortBtn {
  color: var(--red);
}

.checkRowPointWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: solid var(--border-color-primary) 1px;
  margin-top: 20px;
  border-radius: var(--border-radius);

  div {
    font-size: 12px !important;
  }

  input {
    font-size: 12px !important;
  }
}

.latLngWrapper {
  display: flex;
}


.removeBtnWrapper {
  position: absolute;
  top: -20px;
  right: -19px;
  border-radius: 50%;
  border: solid var(--border-color-primary);
  background-color: var(--bg-primary);
  z-index: 99;
  transform: scale(0.6);

  svg {
    transform: scale(0.8);
  }

  &:hover {
    background-color: var(--bg-primary);
  }
}

.posLabelWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 8px;
  align-items: center;
  width: 40%;
  padding-right: 32px;
}

.inputsLngLat {
  width: 60%;
  display: flex;
}

.paramRow {
  margin-top: 8px !important;
  input {
    height: 16px !important;
  }
}