.previewWrapper {
  padding: 32px;
  display: flex;
  width: 40%;
  min-width: 450px;
  justify-content: center;
  height: calc(70vh + 16px);
  overflow: auto;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius);
}

.mapShotWrapper {
  width: 327px;
  height: 491px;

}

.storyDesc {
  font-weight: 200 !important;
}

.storyTitle {
  font-weight: 400 !important;
  font-size: 15px !important;
}

.routeDesc {
  padding: 0 24px 40px 24px;
}

.descTitle {
  font-weight: 700;
  margin-bottom: 16px;

}

.infoRow {
  padding: 12px 24px;
  div {
    margin-top: 4px;
  }
}

.mainInfoTitle {
 font-size: 15px!important;
  font-weight: 200;
}

.mainInfoValue {
  font-size: 20px!important;
  font-weight: 400;
  line-height: 25px;
}

.info {
  font-size: 20px !important;
}

.previewMobile {
  overflow: hidden;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius-mobile);
  width: 375px;
  min-width: 375px;
  height: fit-content;
  min-height: 60vh;
}

.top {
  padding: 12px 24px;

  height: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.performanceLogo {
  text-align: center !important;
  max-width: 215px;
  min-width: 215px;
  max-height: 215px;
  min-height: 215px;
  margin-bottom: 16px;
}

.bordered {
  border: solid var(--typo-white) 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.performanceName {
  font-size: 56px !important;
  text-align: center !important;
}

.body {
  background-color: rgba(31, 31, 45, 0.5);
  height: 100%;
}

.pointWrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 4px;
  margin-bottom: 8px;
}

.pointLeftWrapper {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.pointRightWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.numberCircleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 24px;
  height: 24px;
  background-color: var(--bg-primary);
  border-radius: 50%;
}

.pointName {
  width: 100%;
  font-size: 15px !important;
  font-weight: 500;
}

.pointAddress {
  width: 100%;
  font-size: 11px !important;
  opacity: 0.75;
}

.stick {
  opacity: 0.25;
  height: 16px;
  width: 1px;
  background-color: var(--bg-primary);
}