.spinnerWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);

    z-index: 999;
    svg {
        color: var(--red) !important;
    }
}

.spinnerSm {
    width: 20px !important;
    height: 20px !important;
}

.spinnerMd {
    width: 30px !important;
    height: 30px !important;
}

.spinnerLg {
    width: 40px !important;
    height: 40px !important;
}