
.commonParamRow {
  margin-top: 12px !important;

  div {
    font-size: 14px;
  }
}

.uploadFileInput {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notice {
  color: var(--yellow);
  margin-left: 16px;
}

.soonAndArchiveControlsWrapper {
  border-top: solid var(--border-color-primary) 1px;
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.successUploaded {
  color: var(--green) !important;
  margin-left: 16px;
}

.inputsWrapper {
  position: relative;
  margin-top: 16px;
  width: 100%;
  height: calc(70vh - 40px);
  overflow: auto;
  padding: 16px;
  border: solid var(--border-color-primary) 1px;
  border-radius: var(--border-radius);

}