
.colorToolsWrapper {
  height: calc(70vh - 40px);
  margin-top: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}


.colorParamRow {
  div {
    font-size: 14px;
  }
}


.colorControlWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.manualInput {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.colorHexInput {
  width: 165px !important;

  input {
    height: 12px;
  }
}