.mainPageWrapper {
  height: 100vh;
  padding: var(--viewport-padding);
  background-color: var(--bg-primary);
}

.topControls {
  margin: 0 20px;
  min-height: var(--tab-controls-height);
  max-height: var(--tab-controls-height);
  border-bottom: solid var(--border-color-secondary) 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentTabs {
  position: relative;
  min-height: var(--tab-controls-height);
  max-height: var(--tab-controls-height);
  width: 100%;
}

.loginTitle {
  min-width: fit-content;
  margin-right: 8px;
}

.logoutBtn {
  margin-left: 8px;
}

.userPanel {
  z-index: 999;
  background-color: var(--blue);
  border-radius: var(--border-radius);
  height: calc(var(--tab-controls-height) - 20px);
  min-width: 350px;
  max-width: 350px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px 16px;
  gap: 8px;

  svg {
    color: var(--typo-white);
  }

  div {
    //padding-bottom: 4px;
    color: var(--typo-white);
  }
}

.contentWrapper {
  position: relative;
  height: calc(100% - 60px - 40px);
  width: calc(100vw - 32px);
  min-height: 400px;
  max-width: calc(100vw - 32px);
  min-width: calc(100vw - 32px);
  padding: var(--content-wrapper-padding);
  border-bottom: solid var(--border-color-primary) 1px;
}